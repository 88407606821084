import { createContext, useContext } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { ProFormDataType } from "../form-instance";

export type ProFormContextType<T extends FieldValues = ProFormDataType> = {
  form?: UseFormReturn<T>;
  handleSubmit?: () => Promise<void>;
  layout?: "horizontal" | "vertical";
  wrapperCol?: { span: number };
};

export const ProFormContext = createContext<ProFormContextType>({
  layout: "horizontal",
});

export const useProFormContext = () => useContext(ProFormContext);
