"use client";

import { Button, ButtonProps } from "@shadcn-ui/button";
import { getTransitions } from "i18n";
import { FC } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";

import { useProFormContext } from "../form-context";

type ProFormResetButtonProps = {
  form?: UseFormReturn<Record<string, unknown>>;
} & Omit<ButtonProps, "form">;
export const ProFormResetButton: FC<ProFormResetButtonProps> = (params) => {
  const t = getTransitions();
  const { children, form, ...props } = params;
  const formctx = useFormContext();
  const { handleSubmit } = useProFormContext();
  const formInstance = form ?? formctx;

  const handleMouseDown = async (e: React.MouseEvent<HTMLButtonElement>) => {
    formInstance.reset();
    props?.onMouseDown?.(e);
    const isVerify = await formInstance.trigger();
    if (isVerify) {
      handleSubmit?.();
      props?.onReset?.(e);
    }
  };
  return (
    <Button
      {...props}
      onMouseDown={handleMouseDown}
      type="reset"
      variant="secondary"
    >
      {children ?? t("common.reset")}
    </Button>
  );
};
