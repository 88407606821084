"use client";

import { ReactNode } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { Form } from "../../../shadcn-ui/ui/form";
import { ProFormContext, ProFormContextType } from "../form-context";
import * as ProFormItems from "../form-form-item";
import { ProFormResetButton } from "../reset-button";
import { ProFormSubmitButton } from "../submit-button";
import { FormActions, FormActionsProps } from "./form-actions";

export type ProFormDataType = Record<string, unknown>;

export type ProFormProps<T extends ProFormDataType = any> = {
  children?: ReactNode;
  form?: UseFormReturn<T>;
  onSubmit?: (formData: T) => void; // 通过表单校验后触发
} & FormActionsProps &
  Pick<ProFormContextType<T>, "layout" | "wrapperCol">;

/** 内部Form表单
 * import { useForm } from "react-hook-form";
 * const form = useForm({defaultValues:{}});
 * return <ProForm form={form}>
 */
const InternalForm = function (props: ProFormProps) {
  const defaultForm = useForm();
  const {
    actions = {},
    form,
    layout = "horizontal",
    wrapperCol = { span: 8 },
  } = props;
  const _form = form ?? defaultForm;

  /** 提交表单 */
  const handleSubmit = async () => {
    console.log("Pro Form Values: ", _form.getValues());
    const isVarify = await _form.trigger();
    isVarify && props?.onSubmit?.(_form.getValues());
  };

  /** 提供ProFormContext */
  const contextValues = { form: _form, handleSubmit, layout, wrapperCol };

  return (
    <ProFormContext.Provider value={contextValues}>
      <Form {..._form}>
        <form onSubmit={_form.handleSubmit(handleSubmit)}>
          <div
            className={"grid gap-3"}
            style={{
              gridTemplateColumns: "repeat(24, minmax(0, 1fr))", // 创建24列
            }}
          >
            {props?.children}
          </div>
          {actions && <FormActions {...props} />}
        </form>
      </Form>
    </ProFormContext.Provider>
  );
};

type InternalFormType = typeof InternalForm;
/** ProFormItem */

const ProFormItem = {
  // DatePicker: ProFormItems.DatePickerProForm,
  Input: ProFormItems.InputProForm,
  InputNumber: ProFormItems.InputNumberProForm,
  Select: ProFormItems.SelectProForm,
  Textarea: ProFormItems.TextareaProForm,
};
interface InternalFormProps extends InternalFormType {
  Item: typeof ProFormItem;
  ResetButton: typeof ProFormResetButton;
  SubmitButton: typeof ProFormSubmitButton;
}
/** 挂件化的表单 */
const ProForm = InternalForm as InternalFormProps;
ProForm.SubmitButton = ProFormSubmitButton;
ProForm.ResetButton = ProFormResetButton;
ProForm.Item = ProFormItem;

export { ProForm, ProFormContext, ProFormItem };
