"use client";

import clsx from "clsx";
import * as echarts from "echarts";
import React, { useEffect, useRef } from "react";

interface EChartsComponentProps {
  className?: string;
  option: echarts.EChartsOption;
  style?: React.CSSProperties;
}
/** Echarts的React封装
 *
 * Example:
 * ```ts
 * const option: EChartsOption = {
 *   series: [{ data: [1, 2, 3, 4, 5], type: "line" }],
 *   xAxis: [{ data: [] }],
 *   yAxis: [{ data: [] }],
 * };
 * <EChartsComponent option={option} />
 * ```
 */
const EChartsComponent: React.FC<EChartsComponentProps> = ({
  className,
  option,
  style,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartRef.current) return;
    const chartInstance = echarts.init(chartRef.current);
    chartInstance.setOption(option);
    const resizeHandler = () => chartInstance.resize();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
      chartInstance.dispose();
    };
  }, [option]);

  return (
    <div
      className={clsx(className, "size-full")}
      ref={chartRef}
      style={style}
    />
  );
};

export default EChartsComponent;
